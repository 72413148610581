import React, { useRef } from 'react';
import { Formik, Form, Field, CccisdInput, CccisdTextarea } from 'cccisd-formik';
import Modal from 'cccisd-modal';
import MailIcon from 'cccisd-icons/envelop';
import Notification from 'cccisd-notification';
import axios from 'cccisd-axios';
import Confirm from 'cccisd-confirm';
import _uniq from 'lodash/uniq';

const Boilerplate = window.cccisd.boilerplate;
const Env = window.cccisd.env;

const SendEmail = props => {
    const modal = useRef();

    const closeModal = () => {
        modal.current.close();
    };

    const onSubmit = async values => {
        const emails = _uniq(
            props.row['childRoles.orgAdminList']
                .map(item => item?.user?.email)
                .concat(props.row['childRoles.preventionTeamMemberList'].map(item => item?.user?.email))
        );

        if (emails?.length > 0) {
            Confirm({
                message: `Send email to ${emails.length} IPPW Team Leads and IPPW Team Members?`,
                confirmLabel: 'Send',
                abortLabel: 'Cancel',
            })
                .then(async () => {
                    const response = await axios.post(Boilerplate.route('send.ippact.email'), {
                        usernames: emails,
                        subject: values.subject,
                        body: values.body,
                        actionText: values.actionText,
                        actionUrl: values.actionUrl,
                    });

                    // onsuccess
                    if (response?.data?.status === 'success') {
                        closeModal();

                        Notification({
                            message: `Email sent to ${emails.length} IPPW Team Leads and IPPW Team Members`,
                            type: 'success',
                        });
                    } else {
                        closeModal();

                        Notification({
                            message: `An error occured sending email`,
                            type: 'danger',
                        });
                    }
                })
                .fail(() => {
                    // womp womp
                });
        } else {
            Notification({ message: `There are no IPPW Team Leads or IPPW Team Members`, type: 'danger' });
        }
    };

    const validate = values => {
        const errors = {};

        if (!values.subject || values.subject === '') {
            errors.subject = 'Subject is required';
        }

        if (!values.body || values.body === '') {
            errors.body = 'Body is required';
        }

        if (values.actionText && !values.actionUrl) {
            errors.actionUrl = 'Must have both button text and URL for the button to be visible';
        }

        if (values.actionUrl && !values.actionText) {
            errors.actionText = 'Must have both button text and URL for the button to be visible';
        }

        if (values.actionUrl && !values.actionUrl.match(/^https:\/\/[^.]{1,}\.[^.]{1,}/)) {
            errors.actionUrl = 'Must be valid URL';
        }

        return errors;
    };
    return (
        <Modal
            ref={modal}
            trigger={
                <button type="button" className="btn btn-success">
                    <MailIcon spaceRight /> Send Email
                </button>
            }
            title="Send email to Team Leads and Team Members"
        >
            <Formik
                initialValues={{
                    actionText: `Go to ${Env.name}`,
                    actionUrl: Boilerplate.url('/dashboard'),
                }}
                onSubmit={onSubmit}
                validate={validate}
                render={() => {
                    return (
                        <Form>
                            <Field
                                name="subject"
                                component={CccisdInput}
                                label="Subject"
                                placeholder="Enter email subject"
                            />
                            <Field
                                name="body"
                                component={CccisdTextarea}
                                label="Body"
                                placeholder="Enter email body"
                                rows="8"
                            />
                            <Field name="actionText" component={CccisdInput} label="Button Text" />
                            <Field name="actionUrl" component={CccisdInput} label="Button URL" />

                            <button className="btn btn-primary" type="submit">
                                Submit
                            </button>
                        </Form>
                    );
                }}
            />
        </Modal>
    );
};

export default SendEmail;
