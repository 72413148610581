import React, { useEffect, useState } from 'react';
import { isAfter, parseISO, subWeeks } from 'date-fns';
import { client } from 'cccisd-apollo';
import Loader from 'cccisd-loader';
import Table from 'cccisd-table';
import StatusStartRender from '../StatusStartRender';
import progressQuery from './progress.graphql';

// activity{activity number}_{timepoint}   i.e. activit1_2
const ACTIVITY_DEPLOYMENT_HANDLE_PREFIX = 'activity';

const Fortress = window.cccisd.fortress;
const Appdefs = window.cccisd.appDefs;

const DashTable = ({ metricsPawn, settings, hasEditPermission }) => {
    // TO-DO TODO: instead of using Org created_at, use the Minimum(Team Lead/Team Membmer -> created_at)
    const orgCreatedAt = new Date(Fortress.user.acting.group.created_at);
    const now = new Date();
    const dates = Appdefs.app.dates || [];

    const [isLoading, setIsLoading] = useState(true);
    const [columnDefinitions, setColumnDefinitions] = useState(null);
    const [tableData, setTableData] = useState(null);

    const initialize = () => {
        getColumnDefinitions();
    };

    useEffect(() => {
        initialize();
    }, []);

    useEffect(() => {
        if (columnDefinitions) {
            getTableData();
        }
    }, [columnDefinitions]);

    async function getColumnDefinitions() {
        const columnDefs = [
            {
                name: 'name',
                label: 'Activity Name',
                sort: true,
                filter: true,
                setStyle: () => ({ verticalAlign: 'middle', width: '250px' }),
            },
        ];

        let endUserTimepoint = 1;
        dates.forEach(date => {
            if (
                isAfter(orgCreatedAt, parseISO(date.closesAtISO)) ||
                isAfter(subWeeks(parseISO(date.opensAtISO), 4), now) // show the next timepoint about a month in advance of it opening
            ) {
                return;
            }

            // copy endUserTimepoint instead of pass by reference
            const displayTimepoint = endUserTimepoint;
            columnDefs.push({
                realTimepoint: date.timepoint,
                name: 'realTimepoint' + date.timepoint,
                label: 'Time ' + endUserTimepoint,
                setStyle: () => ({ verticalAlign: 'middle', textAlign: 'center' }),
                render: (value, row) => {
                    return (
                        <StatusStartRender
                            closesAtISO={date.closesAtISO}
                            endUserTimepoint={displayTimepoint}
                            hasEditPermission={hasEditPermission}
                            metricsPawn={metricsPawn}
                            opensAtISO={date.opensAtISO}
                            reloadTableData={() => initialize()}
                            row={row}
                            value={value}
                        />
                    );
                },
            });

            endUserTimepoint++;
        });

        setColumnDefinitions(columnDefs);
    }

    const getTableData = async () => {
        if (!columnDefinitions) {
            return;
        }

        const response = await client.query({
            query: progressQuery,
            fetchPolicy: 'network-only',
            variables: {
                metricsPawnId: metricsPawn.pawnId,
            },
        });

        // create map based on deployment handle for faster lookup
        const progressMap = response.data.roles.metricspawn.assignmentProgressList.reduce((mapper, curr) => {
            mapper[curr.deployment.deploymentHandle] = {
                completedAt: curr.completedAt,
                lastVisitedAt: curr.lastVisitedAt,
            };
            return mapper;
        }, {});

        // 1 row per activity added; 1 column per columnDefinitions
        const data = settings.activities.map(act => {
            return columnDefinitions.reduce((fullRow, currColumn) => {
                if (currColumn.name === 'name') {
                    fullRow.name = act.name;
                } else {
                    const currRealTp = parseInt(currColumn.realTimepoint, 10);
                    const isSupposedToBeComplete = parseInt(act.createdDuringTimepoint, 10) <= currRealTp;
                    const previousDeploymentHandle =
                        currRealTp <= 1 ? null : `${ACTIVITY_DEPLOYMENT_HANDLE_PREFIX}${act.id}_${currRealTp - 1}`;
                    const deploymentHandle = `${ACTIVITY_DEPLOYMENT_HANDLE_PREFIX}${act.id}_${currColumn.realTimepoint}`;
                    fullRow[currColumn.name] = progressMap[deploymentHandle]
                        ? {
                              ...progressMap[deploymentHandle],
                              deploymentHandle,
                              previousDeploymentHandle,
                              isSupposedToBeComplete,
                          }
                        : {
                              deploymentHandle,
                              previousDeploymentHandle,
                              isSupposedToBeComplete,
                              lastVisitedAt: null,
                              completedAt: null,
                          };
                }

                return fullRow;
            }, {});
        });

        setTableData(data);
        setIsLoading(false);
    };

    if (isLoading) {
        return <Loader loading />;
    }

    return (
        <div style={{ marginTop: '2em' }}>
            <Table
                name="ippact_dashboard"
                rowKey="name"
                data={tableData}
                columns={columnDefinitions}
                hideShowingRowsSummary
                showPerPageOptions={false}
                noRecordsMessage={
                    <span>
                        Click &quot;<b>Add Activity</b>&quot; to begin
                    </span>
                }
            />
        </div>
    );
};

export default DashTable;
