import React, { useState, useRef } from 'react';
import _uniq from 'lodash/uniq';
import axios from 'cccisd-axios';
import confirmation from 'cccisd-confirm';
import { Formik, Form, Field, CccisdInput, CccisdWysiwyg } from 'cccisd-formik';
import Table from 'cccisd-graphql-table';
import { MessageViewer } from 'cccisd-laravel-assignment';
import IconCaution from 'cccisd-icons/warning';
import notification from 'cccisd-notification';
import Tooltip from 'cccisd-tooltip';

import query from './query.graphql';
import SendEmail from './SendEmail';
import style from './style.css';

const Appdefs = window.cccisd.appDefs;
const dates = Appdefs.app.dates;
const serviceField = Appdefs.pawn.fields.find(f => f.handle === 'service');
const Boilerplate = window.cccisd.boilerplate;
const Env = window.cccisd.env;

const AdminProgress = () => {
    const [timepoint, setTimepoint] = useState('1');
    const table = useRef();

    const columns = [
        {
            name: 'group.label',
            label: 'Military Organization',
            sort: true,
            filter: true,
        },
        {
            name: 'fields.service',
            label: 'Service',
            sort: true,
            filter: true,
            filterSettings: {
                type: 'selectbox',
                options: serviceField.values.map(v => ({ value: v.value, label: v.name })),
            },
            render: ({ value }) => {
                return serviceField.values.find(v => v.value === value)?.name;
            },
        },
        {
            name: 'group.settings',
            label: 'Activities',
            sort: true,
            filter: true,
            render: ({ row, value }) => {
                const allActivities = row['group.settings.activities'];
                if (!allActivities?.length) {
                    return '0';
                }

                const activitieAtTheTime = allActivities.filter(act => {
                    return parseInt(act?.createdDuringTimepoint || 1, 10) <= parseInt(timepoint, 10);
                });
                return activitieAtTheTime.length;
            },
        },
        {
            name: 'childRoles.metricspawn.activitesComplete',
            label: 'Activities Completed',
            sort: true,
            filter: true,
        },
        {
            name: 'childRoles.metricspawn.lastVisitedAt.lastVisitedAt',
            label: 'Last Visited',
            sort: true,
            filter: true,
        },
        {
            name: 'childRoles.metricspawn.completedAt.completedAt',
            label: 'Completed',
            sort: true,
            filter: true,
            render: ({ loading, row }) => {
                if (loading) {
                    return '';
                }

                const allActivities = row['group.settings.activities'];
                if (!allActivities?.length) {
                    return '';
                }

                const expectedActivitiesComplete = allActivities.filter(act => {
                    return parseInt(act?.createdDuringTimepoint || 1, 10) <= parseInt(timepoint, 10);
                });

                const isActivitesComplete =
                    row['childRoles.metricspawn.activitesComplete'] >= expectedActivitiesComplete.length;
                const isTimetrackerComplete = row['childRoles.metricspawn.timetrackerComplete'] > 0;

                if (isActivitesComplete && isTimetrackerComplete) {
                    return row['childRoles.metricspawn.completedAt.completedAt'];
                }

                if (isActivitesComplete && !isTimetrackerComplete) {
                    return (
                        <span>
                            <span className="text-warning">
                                <IconCaution spaceRight />
                            </span>
                            Missing IPPW Time
                        </span>
                    );
                }

                return '';
            },
        },
        {
            name: 'group.groupId',
            label: 'Send Email',
            render: props => {
                return <SendEmail {...props} />;
            },
        },
    ];

    const SendBulkMessages = ({ selectedRows, loadData, closeModal, extraData }) => {
        const [view, setView] = useState('form');

        const allOrgs = (extraData['allOrganizations.organizationList'] || []).reduce((all, currOrg) => {
            all[currOrg.group.groupId] = currOrg;
            return all;
        }, {});

        let orgAdminList = [];
        let teamMemberList = [];
        let selectedOrgs = [];
        selectedRows.forEach(groupId => {
            if (groupId in allOrgs && allOrgs[groupId]) {
                const currOrg = allOrgs[groupId];
                selectedOrgs.push(currOrg);
                orgAdminList = orgAdminList.concat(currOrg.childRoles.orgAdminList);
                teamMemberList = teamMemberList.concat(currOrg.childRoles.preventionTeamMemberList);
            }
        });

        const recipients = _uniq(
            orgAdminList.map(admin => admin.user.email).concat(teamMemberList.map(tm => tm.user.email))
        );

        const handleValidate = values => {
            const errors = {};

            if (!values.subject) {
                errors.subject = 'Subject is required';
            }
            if (!values.body) {
                errors.body = 'Body is required';
            }
            if ((values.actionText && !values.actionUrl) || (values.actionUrl && !values.actionText)) {
                const err = 'Button Text and Button URL must be either both defined or both empty';
                errors.actionUrl = err;
                errors.actionText = err;
            }
            if (values.actionUrl && !values.actionUrl.match(/^https:\/\/[^.]{1,}\.[^.]{1,}/)) {
                errors.actionUrl = 'Must be valid URL';
            }

            return errors;
        };

        const sendBulkMessages = async values => {
            const response = await axios.post(Boilerplate.route('send.ippact.email'), {
                usernames: recipients,
                subject: values.subject,
                body: values.body,
                actionText: values.actionText,
                actionUrl: values.actionUrl,
            });

            // onsuccess
            if (response?.data?.status === 'success') {
                notification({
                    message: `Email sent to ${recipients.length} IPPW Team Lead(s) and IPPW Team Member(s) at ${selectedOrgs.length} Organization(s)`,
                    type: 'success',
                });
                loadData();
                closeModal();
            } else {
                notification({
                    message: `An error occured sending emails`,
                    type: 'danger',
                });
                closeModal();
            }
        };

        if (Array.isArray(recipients) && recipients.length <= 0) {
            return (
                <div className="alert alert-info">
                    <p>There are no IPPW Team Leads or IPPW Team Members in the selected Military Organizations</p>
                </div>
            );
        }

        return (
            <>
                {Array.isArray(selectedOrgs) && Array.isArray(recipients) && (
                    <Formik
                        initialValues={{
                            actionText: `Go to ${Env.name}`,
                            actionUrl: Boilerplate.url('/dashboard'),
                        }}
                        onSubmit={values => {
                            confirmation(
                                `Send email to ${recipients.length} IPPW Team Lead(s) and IPPW Team Member(s) at ${selectedOrgs.length} Organization(s)?`
                            ).then(() => {
                                sendBulkMessages(values);
                            });
                        }}
                        validate={handleValidate}
                    >
                        {({ handleSubmit, values }) => {
                            const isPreviewDisabled = view === 'form' && (!values.subject || !values.body);
                            let previewButton = (
                                <button
                                    type="button"
                                    className={view === 'form' ? 'btn btn-default' : 'btn btn-success'}
                                    disabled={isPreviewDisabled}
                                    style={{ marginRight: '1em' }}
                                    onClick={() => {
                                        if (view === 'form') {
                                            setView('preview');
                                        }
                                        if (view === 'preview') {
                                            setView('form');
                                        }
                                    }}
                                >
                                    {view === 'form' ? 'Preview' : 'Edit'}
                                </button>
                            );
                            if (isPreviewDisabled) {
                                previewButton = (
                                    <Tooltip title="Subject and Body are required">{previewButton}</Tooltip>
                                );
                            }

                            return (
                                <Form>
                                    {view === 'form' ? (
                                        <>
                                            <label className={style.required}>Subject:</label>
                                            <Field name="subject" component={CccisdInput} required />
                                            <label className={style.required}>Body:</label>
                                            <Field
                                                name="body"
                                                component={CccisdWysiwyg}
                                                type="textarea"
                                                settings={{
                                                    toolbar:
                                                        'undo redo | bold italic underline link | alignleft aligncenter alignright alignjustify | indent outdent',
                                                    menubar: false,
                                                }}
                                            />
                                            <Field name="actionText" component={CccisdInput} label="Button Text" />
                                            <Field name="actionUrl" component={CccisdInput} label="Button URL" />
                                        </>
                                    ) : (
                                        <>
                                            <MessageViewer
                                                deployment={{ hash: '' }} // mock it
                                                messageObject={{
                                                    category: 'preview',
                                                    messageMethod: 'email',
                                                    settings: {
                                                        label: 'Bulk Message',
                                                        recipientsType: 'Selected Organizations',
                                                    },
                                                    recipients: selectedOrgs.map(org => org.group.label),
                                                    condition: 'On Submit',
                                                    messageInfo: {
                                                        subject: values.subject,
                                                        body: values.body,
                                                        actionText: values.actionText,
                                                        actionUrl: values.actionUrl,
                                                    },
                                                }}
                                            />
                                        </>
                                    )}
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'flex-end',
                                            marginTop: '1em',
                                        }}
                                    >
                                        {previewButton}
                                        <button
                                            type="button"
                                            className="btn btn-primary"
                                            onClick={() => handleSubmit()}
                                        >
                                            Submit
                                        </button>
                                    </div>
                                </Form>
                            );
                        }}
                    </Formik>
                )}
            </>
        );
    };

    return (
        <>
            <h1>Progress</h1>
            <div className={'well ' + style.timepointSelector}>
                <form>
                    <div className="form-group">
                        <label htmlFor="timepoint">Timepoint:</label>
                        <select
                            className="form-control"
                            id="timepoint"
                            value={timepoint}
                            onChange={e => setTimepoint(e.target.value)}
                        >
                            {dates.map(date => {
                                return (
                                    <option key={date.timepoint} value={date.timepoint + ''}>{`Timepoint ${
                                        date.timepoint
                                    } (${date.opensAtISO.substr(0, 10)} to ${date.closesAtISO.substr(0, 10)})`}</option>
                                );
                            })}
                        </select>
                    </div>
                </form>
            </div>
            <div className={style.logLink}>
                <a href="appdefs/logs#messagelog">Go To Message Log</a>
            </div>
            {timepoint ? (
                <Table
                    ref={table}
                    rowKey="group.groupId"
                    query={query}
                    graphqlVariables={{ timepoint }}
                    columns={columns}
                    keepSelectedOnFiltering
                    rowActions={[
                        {
                            name: 'bulkSendMessage',
                            title: 'Bulk Send Message',
                            inModal: true,
                            component: SendBulkMessages,
                            size: 'large',
                        },
                    ]}
                />
            ) : (
                <div className="alert alert-info">Please select a timepoint above.</div>
            )}
        </>
    );
};

export default AdminProgress;
