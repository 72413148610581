import React, { useState } from 'react';
import { format, isAfter, parseISO } from 'date-fns';
import _get from 'lodash/get';
import axios from 'cccisd-axios';
import { DeploymentPlayer } from 'cccisd-laravel-assignment';
import Loader from 'cccisd-loader';
import Modal from 'cccisd-modal';
import Tooltip from 'cccisd-tooltip';
import style from './style.css';

const Boilerplate = window.cccisd.boilerplate;

const StatusStartRender = ({
    closesAtISO,
    endUserTimepoint,
    hasEditPermission,
    metricsPawn,
    opensAtISO,
    reloadTableData,
    row,
    value,
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const now = new Date();
    const { completedAt, deploymentHandle, previousDeploymentHandle, isSupposedToBeComplete, lastVisitedAt } = value;
    const isOpen = isAfter(now, parseISO(opensAtISO)) && isAfter(parseISO(closesAtISO), now);
    const isPast = !isOpen && isAfter(now, parseISO(closesAtISO));
    const isFuture = !isOpen && isAfter(parseISO(opensAtISO), now);

    async function fetchPreviousTimepointData() {
        // only need to prepopulate survey answers for the open timepoints, not when Reviewing PDF!
        if (!isOpen) {
            return;
        }

        setIsLoading(true);
        // no need to error handle here - worst that happens is it will not pre-populate answers
        await axios.post(Boilerplate.route('app.setInitialResponseSetState'), {
            deploymentHandle,
            previousDeploymentHandle,
            pawnId: metricsPawn.pawnId,
            pawnHash: metricsPawn.pawnHash,
        });
        setIsLoading(false);
    }

    async function setActivityNameFromSurveyResponse() {
        setIsLoading(true);
        const resp = await axios.put(Boilerplate.route('app.setActivityNameFromSurveyResponse'), {
            deploymentHandle,
            pawnId: metricsPawn.pawnId,
            pawnHash: metricsPawn.pawnHash,
        });

        const newName = _get(resp, 'data.data.newActivityName', null);
        const isNewNameDifferent = newName && row.name !== newName;
        if (isNewNameDifferent) {
            window.location.reload();
            await new Promise(res => setTimeout(res, 6000));
        }

        setIsLoading(false);
    }

    // Activities added late have no data to review
    if (!isSupposedToBeComplete) {
        return <span>-</span>;
    }

    let buttonText = '';
    let className = '';
    let disabled = true;
    let forcePageOneStart = false;
    switch (true) {
        // Not Started
        case !lastVisitedAt && isPast:
            return 'Not Started';
            break; // eslint-disable-line
        case !lastVisitedAt && isOpen:
            buttonText = hasEditPermission ? 'Start' : 'Not Started';
            className = 'btn btn-success';
            disabled = isFuture || !hasEditPermission;
            forcePageOneStart = true;
            break;
        case !lastVisitedAt && isFuture:
            buttonText = hasEditPermission ? 'Start' : 'Not Started';
            className = 'btn btn-success';
            disabled = true;
            break;

        // In Progres
        case lastVisitedAt && !completedAt && isPast:
            buttonText = 'Review';
            className = 'btn btn-info';
            disabled = false;
            break;
        case lastVisitedAt && !completedAt && isOpen:
            buttonText = hasEditPermission ? 'Resume' : 'Review';
            className = 'btn btn-warning';
            disabled = false;
            break;

        // Complete
        case completedAt && isPast:
            buttonText = 'Review';
            className = 'btn btn-info';
            disabled = false;
            break;
        case completedAt && isOpen:
            buttonText = hasEditPermission ? 'Edit' : 'Review';
            className = 'btn btn-primary';
            disabled = false;
            break;
        default:
            break;
    }

    if (isFuture) {
        buttonText = (
            <Tooltip title={'Scheduled to open on ' + format(parseISO(opensAtISO), 'MM/dd/yyyy')}>{buttonText}</Tooltip>
        );
    }

    return (
        <Loader loading={isLoading}>
            <Modal
                trigger={
                    <button type="button" disabled={disabled} className={className}>
                        {buttonText}
                    </button>
                }
                size="large"
                title={`${row.name}: Time ${endUserTimepoint}`}
                beforeShow={fetchPreviousTimepointData}
                beforeClose={setActivityNameFromSurveyResponse}
                afterClose={reloadTableData}
            >
                <DeploymentPlayer
                    pawnId={metricsPawn.pawnId}
                    pawnHash={metricsPawn.pawnHash}
                    deploymentHandle={deploymentHandle}
                    disableLayout
                    flowProps={{
                        isPrintMode: !hasEditPermission || !isOpen,
                        defaultValueVariables: { activityName: row.name },

                        /* We copied response_set_entity_states, but we still want to
                         * force them to start at the beginning of survey when opening
                         * for the first time in the given timepoint */
                        ...(forcePageOneStart ? { pageFirstElementId: 84 } : {}),
                    }}
                />
            </Modal>
            {isOpen && lastVisitedAt && !completedAt && (
                <p className={style.lastEdited}>Last edited {format(new Date(lastVisitedAt), 'MM/dd/yyyy')}</p>
            )}
            {isOpen && completedAt && (
                <p className={style.lastEdited}>Submitted {format(new Date(completedAt), 'MM/dd/yyyy')}</p>
            )}
            {isOpen &&
                completedAt &&
                format(new Date(completedAt), 'MM/dd/yyyy') !== format(new Date(lastVisitedAt), 'MM/dd/yyyy') && (
                    <p className={`${style.lastEdited} ${style.alsoComplete}`}>
                        Edited {format(new Date(lastVisitedAt), 'MM/dd/yyyy')}
                    </p>
                )}
        </Loader>
    );
};

export default StatusStartRender;
